import { Container } from 'semantic-ui-react';
import LoginForm from '../components/LogInForm';
import Layout from '../components/FluidLayout';
import styles from './login.module.scss';

export default function Login() {
  return (
    <Layout>
      <Container className={styles.page}>
        <LoginForm />
      </Container>
    </Layout>
  );
}
